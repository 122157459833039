import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import Loader from "./views/Alert_Popup/Loader";

const Hero = lazy(() => import("./views/Main/Hero"));
const Plans = lazy(() => import("./views/Plans/Plans"));
const Login = lazy(() => import("./views/Login/Login"));
const Register = lazy(() => import("./views/Login/Register"));
const Verify = lazy(() => import("./views/Login/Verify"));
const OrderSummary = lazy(() => import("./views/Order/OrderSummary"));
const CountdownTimer = lazy(() => import("./views/Order/CountDownTimer"));
const OrderConfirmation = lazy(() => import("./views/Order/OrderConfirmation"));
const AlertPopup = lazy(() => import("./views/Alert_Popup/AlertPopup"));
const EsimDetail = lazy(() => import("./views/Esim_Detail/EsimDetail"));
const ReferralModule = lazy(() =>
  import("./views/Referral_module/ReferralModule")
);
const ForgotPassword = lazy(() => import("./views/Login/ForgotPassword"));
const MyEsim = lazy(() => import("./views/Esim_Detail/MyEsim"));
const AboutUs = lazy(() => import("./views/Main/AboutUS"));
const FAQ = lazy(() => import("./views/Main/Faq"));
const PrivacyPolicy = lazy(() => import("./views/Common/PrivacyPolicy"));
const TermsAndCondition = lazy(() =>
  import("./views/Common/TermsAndCondition")
);
const AUP = lazy(() => import("./views/Common/Aup"));
const Partner = lazy(() => import("./views/Main/Partner"));
const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));

const KioskHome = lazy(() => import("./views/Kiosk/KioskHome"));
const KioskPlans = lazy(() => import("./views/Kiosk/KioskPlans"));
const KioskPlanDetails = lazy(() => import("./views/Kiosk/KioskPlanDetails"));
const KioskCheckout = lazy(() => import("./views/Kiosk/KioskCheckout"));
const KioskPayment = lazy(() => import("./views/Kiosk/KioskPayment"));
const KioskPaymentSuccess = lazy(() =>
  import("./views/Kiosk/KioskPaymentSuccess")
);
const KioskQuickInstallation = lazy(() =>
  import("./views/Kiosk/QuickInstallation")
);
const KioskPaymentRedirect = lazy(() =>
  import("./views/Login/KioskPaymentRedirect")
);
const SetPassword = lazy(() => import("./views/Login/SetPassword"));
const STWMain = lazy(() => import("./views/Kiosk/SpinTheWheel/STWMain"));
const KioskLogin = lazy(() => import("./views/Kiosk/KioskLogin"));
// const StudentRegistration = lazy(() =>
//   import("./views/Login/StudentRegistration")
// );
const EsimCompatible = lazy(() => import("./views/Common/EsimCompatible"));
const ResetPassword = lazy(() => import("./views/Login/ResetPassword"));
const Blog = lazy(() => import("./views/Main/Blog/Blog"));
const BlogDetails = lazy(() => import("./views/Main/Blog/BlogDetails"));
const NotFound = lazy(() => import("./views/Common/NotFound"));

export default function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact component={Hero} />
          {/* <Hero /> */}
          {/* </Route> */}
          <Route path="/plans/:planId/:alpha2" component={Plans} />
          <Route path="/plans/:alpha2" component={Plans} />
          <Route path="/rplans/:planId/:regionId" component={Plans} />
          <Route path="/rplans/:regionId" component={Plans} />
          <Route path="/gplans" component={Plans} />
          <Route path="/login" component={Login} />
          <Route path="/Register/:referralCode" component={Register} />
          <Route path="/Register" component={Register} />
          <Route path="/verify/:verificationId/:userId" component={Verify} />
          <Route path="/OrderSummary" component={OrderSummary} />
          <Route path="/OrderConfirmation" component={OrderConfirmation} />
          <Route path="/AlertPopup" component={AlertPopup} />
          <Route path="/countdowntimer/:orderId" component={CountdownTimer} />
          <Route path="/Loader" component={Loader} />
          <Route path="/esimDetails/:esimId/:planId" component={EsimDetail} />
          <Route path="/ReferralModule" component={ReferralModule} />
          <Route path="/Dashboard" component={Dashboard} />
          <Route
            path="/forgotPasswordVerify/:verificationId/:userId"
            component={ResetPassword}
          />
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/MyEsim" component={MyEsim} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/faq" component={FAQ} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/termsandcondition" component={TermsAndCondition} />
          <Route path="/partner" component={Partner} />
          <Route path="/acceptableusepolicy" component={AUP} />
          <Route
            path="/kioskpayment/:userId"
            component={KioskPaymentRedirect}
          />
          <Route path="/setPassword/:userId" component={SetPassword} />
          <Route path="/kiosk/plans/:alpha2/:country" component={KioskPlans} />
          <Route
            path="/kiosk/rplans/:regionId/:region"
            component={KioskPlans}
          />
          <Route path="/kiosk/planDetails" component={KioskPlanDetails} />
          <Route path="/kiosk/checkout" component={KioskCheckout} />
          <Route path="/kiosk/payment" component={KioskPayment} />
          <Route path="/kiosk/paymentSuccess" component={KioskPaymentSuccess} />
          <Route
            path="/kiosk/quickInstallation"
            component={KioskQuickInstallation}
          />
          <Route path="/kiosk/spinTheWheel" component={STWMain} />
          <Route path="/kiosk/login" component={KioskLogin} />
          <Route path="/kiosk" component={KioskHome} />
          {/* <Route
            path="/studentRegistration/:referralCode"
            component={StudentRegistration}
          />
          <Route path="/studentRegistration" component={StudentRegistration} /> */}
          <Route path="/esimCompatible" component={EsimCompatible} />
          <Route path="/blogs" component={Blog} />
          <Route path="/blog/:id" component={BlogDetails} />
          <Route path="*" component={NotFound} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}
